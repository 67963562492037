import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { Col, Container } from 'components/Layout/Grid';

import {
  FONT_SIZE_36,
  FONT_SIZE_DOCS_COPY,
  FONT_SIZE_DOCS_LINKS_TOP_LEVEL,
  JAVA_GREEN,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization';
import { largeTablet, desktop } from 'helper/constants/mediaRules';
import landingChoosePlatform from 'S3/images/landing_choose_platform.svg';
import implementMimiCardPhone from 'S3/images/implement_mimi_card_phone.svg';
import landingBuildMimi from 'S3/images/landing_build_mimi.svg';

const StepsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin-bottom: 3rem;

  ${largeTablet(css`
    flex-direction: ${props => (props.reversed ? 'row-reverse' : 'row')};
    justify-content: ${props => (props.reversed ? 'flex-end' : 'flex-start')};
  `)}
`;

const ImageContainer = styled(Col)`
  padding: 0;
  min-width: 280px;
  margin-bottom: 2rem;

  ${largeTablet(css`
    margin-right: ${props => (!props.reversed ? '3.8rem' : '0')};
  `)}

  ${desktop(css`
    min-width: 310px;
  `)}

  > img {
    width: 100%;
  }
`;

const TextContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  ${largeTablet(css`
    margin-right: ${props => (!props.reversed ? '0' : '3.8rem')};
  `)}

`;

const StepTitle = styled.h4`
  margin: 0;
  color: ${JAVA_GREEN};
  font-size: 2em;

  ${largeTablet(css`
    font-size: ${FONT_SIZE_36};
  `)}
`;

const StepBody = styled.p`
  font-size: 1.2em;
  margin: 0.8rem 0;

  ${largeTablet(css`
    font-size: ${FONT_SIZE_DOCS_LINKS_TOP_LEVEL};
  `)}
`;

const StepLink = styled(Link)`
  font-size: ${FONT_SIZE_DOCS_COPY};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const LandingPageSteps = () => {
  const landingStepData = [
    {
      image: landingChoosePlatform,
      title: strings.choosePlatformTitle,
      body: strings.choosePlatformBody,
      reversed: false,
    },
    {
      image: implementMimiCardPhone,
      title: strings.testTechTitle,
      body: strings.testTechBody,
      link: strings.testMimi,
      to: '/solutions/test-evaluate',
      reversed: true,
    },
    {
      image: landingBuildMimi,
      title: strings.buildMimiTitle,
      body: strings.buildMimiBody,
      link: strings.buildMimiLink,
      to: '/solutions/implement-mimi',
      reversed: false,
    },
  ];

  const Steps = () => landingStepData.map(obj => (
    <StepsContainer key={obj.title} reversed={obj.reversed} width={[1, 1, 0.9, 0.75]}>
      <ImageContainer reversed={obj.reversed}>
        <img id="landing-page-step-image" src={obj.image} alt="" />
      </ImageContainer>
      <TextContainer reversed={obj.reversed} width={[1, 0.9, 0.5, 0.6]}>
        <StepTitle id="landing-page-step-title">
          {obj.title}
        </StepTitle>
        <StepBody id="landing-page-step-body">
          {obj.body}
        </StepBody>
        { obj.to && obj.link && (
          <StepLink id={`qa-landing-links-${obj.link.replace(' ', '-').toLowerCase()}`} to={obj.to}>{`${obj.link} >`}</StepLink>
        )}
      </TextContainer>
    </StepsContainer>
  ));

  return (
    <>
      <Steps />
    </>
  );
};

export default LandingPageSteps;
