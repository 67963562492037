import React from 'react';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { tinyPhone, largeTablet, smallTablet, desktop, largeDesktop } from 'helper/constants/mediaRules';
import {
  DropShadow,
  zIndexes,
  DEFAULT_VERTICAL_GUTTER,
  COLOR_FILE_BG,
  FONT_SIZE_DOCS_LINKS_TOP_LEVEL,
  FONT_SIZE_LANDING_HEADER_BODY,
  FONT_SIZE_ROOT,
  JAVA_GREEN,
  MONA_LISA_PINK,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization';

import { LandingHeaderText, LandingHeaderCTA, DocsLinkCTA } from 'src/modules/LandingHeader';

import { PrevArrow, NextArrow } from 'components/Carousel/Arrows';
import {
  PageCTA,
} from 'components/IntegrateMimiUi';
import LandingLogos from 'components/LandingLogos/LandingLogos';
import { Col, Container, Row } from 'components/Layout/Grid';
import LandingPageSteps from 'components/LandingPageSteps/LandingPageSteps';
import LandingPageTextBlock from 'components/LandingPageTextBlock/LandingPageTextBlock';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import greenLandingTriangle from 'S3/images/green_landing_triangle.svg';
import contactLeftSquare from 'S3/images/contact_left_square.svg';
import implementMimi from 'S3/images/implement_mimi.svg';
import partnersIcon from 'S3/images/partners_icon.svg';
import footerLinkIconV2 from 'S3/images/footer_link_icon_V2.svg';

import SEO from '../components/Seo';

const LandingRow = styled(Row)`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: ${DEFAULT_VERTICAL_GUTTER} 0;
  ${largeTablet(css`
    flex-direction: row;
    padding: 8vh 0 ${DEFAULT_VERTICAL_GUTTER} 0;
  `)}
`;

const StyledBlueTriangle = styled.img`
  width: 56rem;
  position: absolute;
  left: -28rem;
  top: 100rem;
  filter: ${DropShadow};
  z-index: ${zIndexes.backgroundShapes};
`;

const StyledGreenTriangle = styled.img`
  position: absolute;
  right: 0;
  top: 30vh;
  filter: ${DropShadow};
  width: 10rem;

  ${smallTablet(css`
    width: 20rem;
  `)}
`;

const StyledRightSquare = styled(Img)`
  width: 16rem;
  position: absolute;
  right: 0;
  top: 148rem;
  z-index: ${zIndexes.backgroundShapes};
`;

const HeaderContentLeft = styled(Col)`
  z-index: ${zIndexes.headerContent};
`;

const HeaderContentRight = styled(Col)`
  z-index: ${zIndexes.headerContent};
`;

const Carousel = styled(Slider)`
  max-width: 100%;

  @media only screen and (max-width: 280px) {
    display: none !important;
  }

  .slick-dots {
    bottom: 92%;

    ${smallTablet(css`
      bottom: 88%;
    `)}

    ${largeTablet(css`
      bottom: 84%;
    `)}

    ${desktop(css`
      bottom: 86%;
    `)}

    > li {
      margin: 0 28px;
      > button {
        &::before {
          opacity: 1;
          color: #fff;
          font-size: 8rem;
          content: '-';
        }
      }
    }

    > .slick-active {
      > button {
        &::before {
          color: ${JAVA_GREEN};
          opacity: 1;
        }
      }
    }
  }
`;

const CenteredContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FullContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: none !important;
  padding-top: 5rem;
  background-color: #142637;
`;

const CenteredRow = styled(Row)`
  display: flex;
  margin: 0 0 4rem 0;
  flex-grow: 0;
  text-align: center;
  justify-content: center;
`;

const FooterContainer = styled(Container)`
  padding-left: 10px;
  padding-right: 10px;
  flex-direction: column;
  align-items: center;
`;

const FooterRow = styled(Row)`
  text-align: center;
  margin: 6.6rem 0;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;

  ${smallTablet(css`
    margin: 10rem 0;
  `)}

  > img {
    width: 70%;
    position: absolute;
    bottom: -10%;
    z-index: ${zIndexes.backgroundShapes};

    ${smallTablet(css`
      bottom: -34%;
    `)}

    ${largeTablet(css`
      width: 62%;
    `)}

    ${largeDesktop(css`
      bottom: -60%;
    `)}
  }
`;

const FooterTitle = styled.h4`
  color: ${MONA_LISA_PINK};
  font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
  white-space: pre-line;

  ${smallTablet(css`
    margin: 0;
  `)}
`;

const ratio = 1 / 2;

const IndexPage = () => {
  const images = useStaticQuery(graphql`
    fragment indexFluid on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query IndexPageImageQuery {
      contactRightSquare: file(relativePath: { eq: "images/contact_right_square.png" }) {...indexFluid}
      landingPhonesV2: file(relativePath: { eq: "images/landing_phones_V2.png" }) {...indexFluid}
      landingCarouselBg1: file(relativePath: { eq: "images/landing_carousel_bg1@2x_V2.png" }) {...indexFluid}
      landingCarouselBg2: file(relativePath: { eq: "images/landing_carousel_bg2@2x_V2.png" }) {...indexFluid}
      landingCarouselBg3: file(relativePath: { eq: "images/landing_carousel_bg3@2x_V2.png" }) {...indexFluid}
      contactRightSquare: file(relativePath: { eq: "images/contact_right_square.png" }) {...indexFluid}
    }
  `);

  const {
    contactRightSquare,
    landingPhonesV2,
    landingCarouselBg1,
    landingCarouselBg2,
    landingCarouselBg3,
  } = images;

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    adaptiveHeight: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <Container>
        <SEO title="Home" />
        <LandingRow>
          <HeaderContentLeft width={[1, 1, ratio]}>
            <LandingHeaderText
              title={strings.landingHeaderTitle}
              body={strings.landingHeaderBody}
            />
            <LandingHeaderCTA />
          </HeaderContentLeft>
          <HeaderContentRight width={[1, 1, 0.43]}>
            <Img className="landing-phones" alt="" fadeIn={false} loading="eager" fluid={landingPhonesV2.childImageSharp.fluid} />
          </HeaderContentRight>
          <StyledGreenTriangle id="bg-square-right-green" src={greenLandingTriangle} alt="" />
        </LandingRow>
      </Container>
      <Container>
        <LandingRow>
          <Carousel {...sliderSettings}>
            <CarouselContents
              title={strings.plugAndPlayTitle}
              text={strings.plugAndPlayDescription}
            >
              <Img className="carousel-image-1" fluid={landingCarouselBg1.childImageSharp.fluid} />
            </CarouselContents>
            <CarouselContents
              title={strings.fullyCustomizableUiTitle}
              text={strings.fullyCustomizableUiDescription}
            >
              <Img className="carousel-image-2" fluid={landingCarouselBg2.childImageSharp.fluid} />
            </CarouselContents>
            <CarouselContents
              title={strings.designedForVersatilityTitle}
              text={strings.designedForVersatilityDescription}
            >
              <Img className="carousel-image-3" fluid={landingCarouselBg3.childImageSharp.fluid} />
            </CarouselContents>
          </Carousel>
        </LandingRow>
      </Container>
      <CenteredContainer>
        <CenteredRow width={[1, 1, 0.6]}>
          <LandingHeaderText body={strings.docsLinkText} />
          <DocsLinkCTA />
        </CenteredRow>
        <CenteredRow width={[1, 1, 0.75]}>
          <StyledBlueTriangle id="bg-square-left" src={contactLeftSquare} alt="" />
          <LandingPageTextBlock
            image={implementMimi}
            alt={'Implementing Mimi Logo.'}
            title={strings.integratingMimi}
            body={strings.integrateMimiLanding}
          />
        </CenteredRow>
      </CenteredContainer>
      <CenteredContainer>
        <LandingPageSteps />
        <StyledRightSquare style={{ position: 'absolute' }} id="bg-square-right" fluid={contactRightSquare.childImageSharp.fluid} alt="" />
      </CenteredContainer>
      <FullContainer>
        <CenteredRow width={[1, 1, 0.75]}>
          <LandingPageTextBlock
            image={partnersIcon}
            alt={'Partner Trio flow'}
            title={strings.partners}
            body={strings.partnersBody}
          />
          <LandingLogos />
        </CenteredRow>
      </FullContainer>
      <FooterContainer>
        <FooterRow width={[0.66, 0.66, 0.58, 0.4]}>
          <img id="footer-thumbprint" src={footerLinkIconV2} alt="Thumbprint" />
          <FooterTitle width={[0.66, 0.66, 0.44, 0.33]} id="landing-footer-text">{strings.landingFooterText}</FooterTitle>
          <PageCTA id="qa-home-page-footer-link" to="/contact-sales">
            {strings.contactSales}
          </PageCTA>
        </FooterRow>
      </FooterContainer>
    </>
  );
};

export default IndexPage;

const CarouselContentsWrapper = styled.div`
  position: relative;
  margin: 10px;
  background-color: ${COLOR_FILE_BG};
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

  ${smallTablet(css`
    margin: 40px;
  `)}

  ${largeTablet(css`
    margin: 80px;
  `)}
`;
CarouselContentsWrapper.displayName = 'CarouselContentsWrapper(index)';

const CarouselContentsText = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  width: 100%;
  padding: 1rem 2rem;
  position: absolute;
  top: 0;
  left: 0;

  ${smallTablet(css`
    padding: 1rem 1.625rem;
  `)}

  ${largeTablet(css`
    padding: 2rem 2.625rem;
  `)}

  h2 {
    color: ${JAVA_GREEN};
    margin-bottom: 1.5rem;
    font-size: ${FONT_SIZE_DOCS_LINKS_TOP_LEVEL};

    ${largeTablet(css`
      font-size: 1.4em;
    `)}
  }

  p {
    max-width: 37.313rem;
    margin: 0;
    font-size: ${FONT_SIZE_ROOT};

    ${tinyPhone(css`
      font-size: 0.8rem;
    `)}

    ${largeTablet(css`
      font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
    `)}
  }
`;
CarouselContentsText.displayName = 'CarouselContentsText(index)';

const CarouselContents = ({ children, title, text }) => (
  <CarouselContentsWrapper>
    {children}
    <CarouselContentsText>
      <span>
        <h2>{title}</h2>
        <p>{text}</p>
      </span>
    </CarouselContentsText>
  </CarouselContentsWrapper>
);
