import React from 'react';
import styled, { css } from 'styled-components';

import { largeTablet, smallTablet } from 'helper/constants/mediaRules';

import leftArrowSrc from 'images/carousel_arrow_left.svg';
import rightArrowSrc from 'images/carousel_arrow_right.svg';

const SharedArrowStyle = css`
  top: 8.5rem;
  z-index: 1;
  position: absolute;
  padding: 0;
  transform: translateY(-50%);
  cursor: pointer;
  color: transparent;
  outline: none;
  background: transparent;
  border: none;
  opacity: .75;

  &:hover {
    opacity: 1;
  }
`;

export const NextArrow = (props) => {
  const { onClick } = props;
  const StyledNextArrow = styled.button`
    right: 1rem;
    ${SharedArrowStyle}

    ${smallTablet(css`
      top: 50%;
      right: 4rem;
    `)}

    ${largeTablet(css`
      top: 50%;
      right: 8rem;
    `)}

    &::before {
      content: url(${rightArrowSrc});
    }
  `;
  return (
    <StyledNextArrow
      id="qa-next-arrow"
      onClick={onClick}
    />
  );
};

export const PrevArrow = (props) => {
  const { onClick } = props;
  const StyledPrevArrow = styled.button`
    left: 1rem;
    ${SharedArrowStyle}

    ${smallTablet(css`
      top: 50%;
      left: 4rem;
    `)}

    ${largeTablet(css`
      top: 50%;
      left: 8rem;
    `)}

    &::before {
      content: url(${leftArrowSrc});
    }
  `;
  return (
    <StyledPrevArrow
      id="qa-prev-arrow"
      onClick={onClick}
    />
  );
};
