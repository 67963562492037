import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import { androidDocsLink, iOSDocsLink } from 'helper/constants/constants';
import { desktop, smallTablet, largeTablet } from 'helper/constants/mediaRules';
import {
  BUNTING,
  MONA_LISA_PINK,
  COLOR_CTA_HOVER_BG,
  FONT_SIZE_LANDING_HEADER_BODY,
  FONT_SIZE_LANDING_HEADER_TITLE,
} from 'helper/constants/styles';
import strings from 'helper/localization/localization';

const LandingHeaderTitle = styled.h1`
  letter-spacing: 1px;
  font-size: 2em;
  white-space: pre-line;

  ${largeTablet(css`
    font-size: ${FONT_SIZE_LANDING_HEADER_TITLE};
  `)}
`;

const LandingHeaderBody = styled.p`
  font-size: 1em;

  ${smallTablet(css`
    font-size: 1.3em;
  `)}

  ${largeTablet(css`
    font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
  `)}
`;

const linkStyle = css`
  display: inline-block;
  background-color: ${MONA_LISA_PINK};
  padding: 10px;
  border-radius: 4px;
  color: ${BUNTING};
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 1rem 0;
  width: 147px;
  text-align: center;
  text-decoration: none;

  :first-of-type {
    margin: 0 1rem 1rem 0;
  }

  &:hover {
    background-color: ${COLOR_CTA_HOVER_BG};
  }

  ${smallTablet(css`
    margin: 0 1rem 1rem 0;
  `)}

  ${largeTablet(css`
    margin: 0 1rem 1rem 0;
  `)}

  ${desktop(css`
    margin: 0 1rem 1rem 0;
  `)}
`;

const LandingCTA = styled(Link)`${linkStyle}`;
const AndroidCTA = styled(Link)`${linkStyle}`;
const IosCTA = styled.a`${linkStyle}`;

export const LandingHeaderText = ({ title, body }) => (
  <>
    <LandingHeaderTitle id="qa-landing-page"> {title}</LandingHeaderTitle>
    <LandingHeaderBody>{body}</LandingHeaderBody>
  </>
);

export const LandingHeaderCTA = () => (
  <>
    <LandingCTA id="qa-contact-sales-home-page-link" to="/contact-sales">
      {strings.contactSales}
    </LandingCTA>
  </>
);

export const DocsLinkCTA = () => (
  <>
    <IosCTA id="qa-ios-docs-home-page-link" rel="noopener noreferrer" target="_blank" href={iOSDocsLink}>
      {'iOS'}
    </IosCTA>
    <AndroidCTA id="qa-android-docs-home-page-link" rel="noopener noreferrer" target="_blank" href={androidDocsLink}>
      {'Android'}
    </AndroidCTA>
  </>
);
