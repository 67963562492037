import React from 'react';
import styled, { css } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Col, Row } from 'components/Layout/Grid';

import { largeTablet, desktop } from 'helper/constants/mediaRules';

const LogoContainer = styled(Row)`
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 0;
  margin-right: 0;


  ${largeTablet(css`
    margin-left: -3rem;
    margin-right: -6rem;
  `)}

  ${desktop(css`
    flex-wrap: nowrap;
  `)}
`;

const LogoCol = styled(Col)`
  padding: 0;
  margin-left: 0;

  ${largeTablet(css`
    margin-left: -3rem;
  `)}
`;

const LandingLogos = () => {
  const images = useStaticQuery(graphql`
    fragment logosFluid on File {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query LandingLogosImageQuery {
      qualcommLogo: file(relativePath: { eq: "images/qualcomm_logo.png" }) {...logosFluid}
      loeweLogo: file(relativePath: { eq: "images/loewe_logo.png" }) {...logosFluid}
      beyerLogo: file(relativePath: { eq: "images/beyer_logo.png" }) {...logosFluid}
      bragiLogo: file(relativePath: { eq: "images/bragi_logo.png" }) {...logosFluid}
      cadenceLogo: file(relativePath: { eq: "images/cadence_logo.png" }) {...logosFluid}
    }
  `);

  const {
    qualcommLogo,
    loeweLogo,
    beyerLogo,
    bragiLogo,
    cadenceLogo,
  } = images;

  return (
    <LogoContainer>
      <LogoCol width={[0.5, 0.3, 0.3, 1]}>
        <Img fluid={qualcommLogo.childImageSharp.fluid} />
      </LogoCol>
      <LogoCol width={[0.5, 0.3, 0.3, 1]}>
        <Img fluid={loeweLogo.childImageSharp.fluid} />
      </LogoCol>
      <LogoCol width={[0.5, 0.3, 0.3, 1]}>
        <Img fluid={beyerLogo.childImageSharp.fluid} />
      </LogoCol>
      <LogoCol width={[0.5, 0.3, 0.3, 1]}>
        <Img fluid={bragiLogo.childImageSharp.fluid} />
      </LogoCol>
      <LogoCol width={[0.5, 0.3, 0.3, 1]}>
        <Img fluid={cadenceLogo.childImageSharp.fluid} />
      </LogoCol>
    </LogoContainer>
  );
};

export default LandingLogos;
