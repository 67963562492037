import React from 'react';
import styled, { css } from 'styled-components';

import { HeaderCol } from 'components/Solutions/SolutionsPageHeader';

import { smallTablet, largeTablet } from 'helper/constants/mediaRules';
import {
  FONT_SIZE_36,
  FONT_SIZE_LANDING_HEADER_BODY,
  JAVA_GREEN,
} from 'helper/constants/styles';

const ColImage = styled.img`
  width: 3rem;
`;

const IntegrateMimiTitle = styled.h1`
  font-size: 1.8em;
  font-weight: 700;
  color: ${JAVA_GREEN};
  margin-top: 0.8rem;
  margin-bottom: 2rem;

  ${smallTablet(css`
    font-size: 2em;
  `)}

  ${largeTablet(css`
    font-size: ${FONT_SIZE_36};
  `)}
`;

const IntegrateMimiBody = styled.p`
  font-size: 1em;

  ${smallTablet(css`
    font-size: 1.3em;
  `)}

  ${largeTablet(css`
    font-size: ${FONT_SIZE_LANDING_HEADER_BODY};
    line-height: 30px;
  `)}
`;

const LandingPageTextBlock = ({ image, alt, title, body }) => (
  <HeaderCol>
    <ColImage id="landing-text-block-image" src={image} alt={alt} />
    <IntegrateMimiTitle>{title}</IntegrateMimiTitle>
    <IntegrateMimiBody>{body}</IntegrateMimiBody>
  </HeaderCol>
);

export default LandingPageTextBlock;
